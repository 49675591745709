<template>
  <div class="register">
    <div class="registerbox">
      <img src="../assets/image/white_logo.png" alt="">
          <el-form ref="form" :model="form">
            <p style="text-align: left;margin: 0px 0px 5px 0px;font-size:16px;">注册</p>
            <el-form-item >
              <el-input v-model="form.email" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item >
              <el-input v-model="form.email" placeholder="电子邮箱"></el-input>
            </el-form-item>
            <el-form-item >
            <el-input v-model="form.email" placeholder="手机号码"></el-input>
            </el-form-item>
            <el-form-item >
              <el-input v-model="form.email" placeholder="密码"></el-input>
            </el-form-item>
            <el-form-item class="loginbtn">
              <span class="">注册</span>
            </el-form-item>
          </el-form>
      <div class="login">已有秉匠账户？ <span @click="gologin">登录</span></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: 'second',
      icon:"el-input__icon el-icon-view",
      passw: 'password',
      form: {
        email: '',
        password: ''
      },
      mobileForm: {
        mobile: '',
        validate: ''
      },
      checkedPass: false
    }
  },
  methods: {
    gologin () {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
.register{
  background-color: #121212;
  color: #fff;
  height: 100%;
  width: 100%;
  padding-top: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  .registerbox{
    width: 450px;
    background: #202020;
    margin: 0px auto;
    border-radius: 5px;
    padding: 60px 0 60px 0;
    img{
      width: 15%;
      margin: 0px auto;
      display: block;
    }
    p{
      font-size: 12px;
      margin-left: 50px;
      margin: 10px 50px 5px;
    }
    .p{
      margin-top: 20px;
      font-size: 14px;
    }
    .login{
      color: #868686;
      font-size: 14px;
      margin-top: 30px;
      span{
        color: #fff;
        margin-left: 50px;
      }
      span:hover{
        color: #00a0ea;
        cursor: pointer;
      }
    }
    /deep/ .el-form{
      width: 350px;
      margin: 30px auto;
      .el-input__inner{
        background-color: #2b2b2b;
        color:#fff;
        height: 50px;
        line-height: 50px;
        border: none;
      }
      .checkedPass{
        .el-checkbox{
          float: left;
        }
        .span{
          float: right;
          color: #868686;
        }
      }
      .el-checkbox__inner{
        background-color: #454545;
        border: none;
      }
      .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #fff;
      }
      .loginbtn{
        background-color: #191919;
        height: 50px;
        line-height: 50px;
        color: #868686;
        padding: 10px 0;
        border-radius: 5px;
        margin-top: 10px;
      }
    }
  }
}
</style>
